import { Utils } from '@ukhomeoffice/cop-react-components';
import TmsTaskTimelineUtil from '../../pages/tms/utils/TmsTaskTimelineUtil';

const { dateFromString, longDateString } = TmsTaskTimelineUtil;

const substituteValuesInConfirmationMessage = (message, payload) => {
  const msg = Utils.interpolateString(message, {
    autoClose: payload.autoClose || 'N/A',
    reviewDateP: payload.reviewDateTime?.reviewDate
      ? longDateString(dateFromString(payload.reviewDateTime.reviewDate))
      : 'N/A',
    reviewTimeP: payload.reviewDateTime?.reviewTime || 'N/A',
  });
  return msg;
};

export default substituteValuesInConfirmationMessage;
