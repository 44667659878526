import { Utils } from '@ukhomeoffice/cop-react-components';

const substituteValuesInConfirmationTitle = (title, payload) => {
  const confirmTitle = Utils.interpolateString(title, {
    childrenNum: payload.childrenLength || 'N/A',
  });
  return confirmTitle;
};

export default substituteValuesInConfirmationTitle;
