const doValueSubstitutionInConfirmationMessage = (form) => {
  if (form?.cya?.insertDateTime) {
    return true; // Return true if insertDateTime exists
  }

  // Otherwise, return false
  return false;
};

export default doValueSubstitutionInConfirmationMessage;
