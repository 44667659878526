// Global imports
import axios from 'axios';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Loader from '@highpoint/react-loader-advanced';
import { FormRenderer, Utils as FRUtils } from '@ukhomeoffice/cop-react-form-renderer';
import { ButtonGroup, Button, Utils } from '@ukhomeoffice/cop-react-components';
import gds from '@ukhomeoffice/formio-gds-template';
import { BLACK, WHITE } from 'govuk-colours';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Formio } from 'react-formio';
import { useNavigation } from 'react-navi';
import { v4 as uuidv4 } from 'uuid';
import { useKeycloak } from '../../utils/KeycloakContext';

// Local imports
import { CurrentGroupContext } from '../../utils/CurrentGroupContext';
import FileService from '../../utils/FileService';
import { augmentRequest, interpolate } from '../../utils/formioSupport';
import {
  formHooks,
  getDocumentTitle,
  getExistingFormData,
  getPageForFormWithTask,
  getRenderer,
  Renderers,
  getShouldGoToEabOne,
  doRedirect,
} from '../../utils/Form';
import { useAxios, loadGroup } from '../../utils/hooks';
import Logger from '../../utils/logger';
import MatomoManager from '../../utils/MatomoManager';
import SecureLocalStorageManager from '../../utils/SecureLocalStorageManager';
import { TeamContext } from '../../utils/TeamContext';
import { LocationContext } from '../../utils/LocationContext';
import FormErrorsAlert from '../alert/FormErrorsAlert';
import ApplicationSpinner from '../ApplicationSpinner';
import AlertPanel from '../alert/AlertPanel';
import defaultCivilServantStatus from '../../utils/Form/defaultCivilServantStatus';
import { CompositeTypes } from '../Common/CompositeComponents';
import getCYARowsForComposite from './utils/getCYARowsForComposite';
import validatePage from './validate/validatePage';
import performAction from './utils/performAction';
import {
  isPendingStaffDetailsPage,
  updatePendingStaffDetail,
} from '../../utils/Form/PendingStaffDetailsHelper';
import authClient from '../../utils/AuthClient';

// Styles
import './DisplayForm.scss';
import { FormPanelContext } from './utils/FormPanelContext';
import onGetComponent from '../../utils/Form/onGetComponent';
import doValueSubstitutionInConfirmationMessage from '../../utils/Form/doValueSubstitutionInConfirmationMessage';
import substituteValuesInConfirmationMessage from '../../utils/Form/substituteValuesInConfirmationMessage';
import doValueSubstitutionInConfirmationTitle from '../../utils/Form/doValueSubstitutionInConfirmationTitle';
import substituteValuesInConfirmationTitle from '../../utils/Form/substituteValuesInConfirmationTitle';

Formio.use(gds);

const DisplayForm = ({
  form,
  className,
  handleOnCancel,
  handleOnCustomEvent,
  handleOnSubmit,
  handleOnSuccess,
  interpolateContext,
  submitting,
  localStorageReference,
  existingSubmission,
  processKey,
  setLoading,
  prepopulate,
}) => {
  const axiosInstance = useAxios();
  const source = axios.CancelToken.source();
  const navigation = useNavigation();
  const [renderer, setRenderer] = useState();
  const [augmentedSubmission, setAugmentedSubmission] = useState();
  const [errorAlert, setErrorAlert] = useState();
  const [hasFormChanged, setHasFormChanged] = useState(false);
  const formRef = useRef();
  const host = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;
  const [newPage, setNewPage] = useState();

  const [changedFieldName, setChangedFieldName] = useState();
  const [changedFieldValue, setChangedFieldValue] = useState();

  const [hideTitle, setHideTitle] = useState(false);
  const coreDocTitle = document.title;

  const [hubPageActions, setHubPageActions] = useState(false);
  const [formBusinessKey, setFormBusinessKey] = useState('');

  const { keycloak } = useKeycloak();
  const client = authClient();
  const { trackPageView } = useMatomo();
  const {
    authServerUrl: url,
    realm,
    refreshToken,
    subject,
    token: accessToken,
    tokenParsed: {
      adelphi_number: adelphi,
      dateofleaving,
      delegate_email: delegateEmail,
      email,
      family_name: familyName,
      given_name: givenName,
      grade_id: gradeId,
      groups,
      line_manager_email: linemanagerEmail,
      location_id: defaultlocationid,
      name,
      phone,
      realm_access: { roles },
      team_id: teamid,
      session_state: sessionId,
      civil_servant: areYouACivilServant = defaultCivilServantStatus(gradeId),
      line_manager_approval_status: linemanagerApprovalStatus,
      delegate_approval_status: delegateApprovalStatus,
      pending_line_manager_email: pendingLinemanagerEmail,
      pending_delegate_email: pendingDelegateEmail,
    },
  } = keycloak;

  const { setFormPanelContext } = useContext(FormPanelContext);

  /* istanbul ignore next */
  Formio.baseUrl = host;
  Formio.projectUrl = host;
  Formio.plugins = [augmentRequest(keycloak, form.id)];

  const fileService = new FileService(keycloak, form.name);

  const [time, setTime] = useState({
    start: null,
    end: null,
    submitted: false,
  });

  const { currentGroup, setCurrentGroup, setGroupLoaded } = useContext(CurrentGroupContext);
  const { team } = useContext(TeamContext);
  const { location } = useContext(LocationContext);

  /**
   * recordBorderEvent/negativeEaB are the json nodes containing the event at border and negative event at border prefill data.
   * Due to the location where the form is expecting auto population data for the event at border form, recordBorderEvent is spread into
   * contexts below. Should the form be accessed from outside of a task, auto poulation will not take place.
   */
  const recordBorderEvent = interpolateContext?.processContext?.recordBorderEvent;
  const negativeEaB = interpolateContext?.processContext?.negativeEaB;

  const contexts = {
    data: {
      environmentContext: {
        attachmentServiceUrl: '/files',
        privateUiUrl: window.location.origin,
        referenceDataUrl: '/refdata',
        workflowUrl: '/camunda',
        tmsDataServiceUrl: '/cop-tms-data-service/v1/dao-service',
      },
      extendedStaffDetailsContext: {
        delegateEmail,
        email,
        linemanagerEmail,
        name,
        linemanagerApprovalStatus,
        delegateApprovalStatus,
        pendingLinemanagerEmail,
        pendingDelegateEmail,
      },
      keycloakContext: {
        accessToken,
        adelphi,
        email,
        familyName,
        givenName,
        gradeId,
        groups,
        locationId: defaultlocationid,
        phone,
        realm,
        refreshToken,
        roles,
        sessionId,
        subject,
        url,
      },
      shiftDetailsContext: {
        email,
        location,
        locationid: defaultlocationid,
        phone,
        roles,
        team: currentGroup,
        teamid,
        currentGroup,
        groups,
      },
      staffDetailsDataContext: {
        adelphi,
        dateofleaving,
        defaultlocation: location,
        defaultlocationid,
        defaultteam: team,
        defaultteamid: teamid,
        email,
        firstname: givenName,
        gradeid: gradeId,
        locationid: defaultlocationid,
        phone,
        surname: familyName,
        teamid,
        areYouACivilServant,
      },
      ...negativeEaB,
      ...recordBorderEvent,
      ...interpolateContext,
    },
  };

  const reformattedContexts = {
    keycloakContext: {
      accessToken: keycloak.token,
      refreshToken: keycloak.refreshToken,
      sessionId: keycloak.tokenParsed.session_state,
      email: keycloak.tokenParsed.email,
      givenName: keycloak.tokenParsed.given_name,
      familyName: keycloak.tokenParsed.family_name,
      subject: keycloak.subject,
      url: keycloak.authServerUrl,
      realm: keycloak.realm,
      roles: keycloak.tokenParsed.realm_access.roles,
      groups: keycloak.tokenParsed.groups,
    },
    ...contexts.data,
  };
  /*
   * augmentedSubmission is fed into the form (on the 'submission' prop) and pre-populates fields where possible.
   * interpolate() is used to pass the context objects to the parent form - this only applies to the parent form, not nested forms
   * Both augmentedSubmission and interpolate() pass context into the <Form> component
   * augmentedSubmission must have context included or it cannot pre-populate fields that rely on context.
   * We have kept interpolate() context to prevent any unwanted side effects of removing it from the parent form.
   */
  interpolate(form, { ...reformattedContexts });

  const currentTaskReference = `task-${localStorageReference}`;

  /* Storing users answers to retain them on page refresh:
   * Answers will persist on return to this page except
   * - when user has submitted the form
   * - or when user has gone to the Dashboard (answers are cleared there)
   * On pageload, once we have obtained the localStorageReference, we check if there is data for this form in localStorage
   * - if there is localStorage data, we use it to create the data for the submission prop for the <Form> component
   */
  useEffect(() => {
    if (!SecureLocalStorageManager.get(localStorageReference)) {
      setAugmentedSubmission(_.merge({ ...existingSubmission }, contexts));
      SecureLocalStorageManager.set(localStorageReference, augmentedSubmission);
    } else {
      let localData = SecureLocalStorageManager.get(localStorageReference);
      let localCurrentTask;
      if (SecureLocalStorageManager.get(currentTaskReference)) {
        localCurrentTask = SecureLocalStorageManager.get(currentTaskReference);
        localData = _.merge({ ...localData }, { ...localCurrentTask });
        localData.currentTask = localCurrentTask;
      }
      setAugmentedSubmission(_.merge({ ...localData }, contexts));
    }
    return () => {
      SecureLocalStorageManager.remove(localStorageReference);
    };
  }, []);

  /*
   * The plugin below is required for when nested forms are present. These nested forms
   * require interpolation as they can also make reference to context
   */
  Formio.registerPlugin(
    {
      priority: 0,
      requestResponse(response) {
        return {
          ok: response.ok,
          json: () =>
            response.json().then((result) => {
              if (!Array.isArray(result) && _.has(result, 'display')) {
                interpolate(result, { ...reformattedContexts });
                return result;
              }
              return result;
            }),
          status: response.status,
          headers: response.headers,
        };
      },
    },
    'processSubFormInterpolation'
  );

  useEffect(() => {
    return () => {
      Formio.deregisterPlugin('processSubFormInterpolation');
    };
  }, []);

  useEffect(() => {
    if (form && form.name && time.end) {
      Logger.info({
        token: keycloak.token,
        path: `/form/${processKey}`,
        message: {
          log: time.submitted ? 'Form has been submitted' : 'Form has been cancelled',
          name: form.name,
          submitted: time.submitted,
          ...time,
          completionTimeInSeconds: moment
            .duration(moment(time.end).diff(moment(time.start)))
            .asSeconds(),
        },
      });
    }
  }, [time, keycloak.token, form]);

  const validate = (formInstance, data) => {
    if (!formInstance || !errorAlert) {
      return;
    }
    let instance;

    // eslint-disable-next-line no-underscore-dangle
    if (formInstance._form.display === 'wizard') {
      instance = formInstance.currentPage;
    } else {
      instance = formInstance;
    }

    if (instance && instance.isValid(data.value, true)) {
      setErrorAlert(null);
    } else {
      const errors = _.filter(
        errorAlert.errors,
        (error) => data.changed && error.component.key !== data.changed.component.key
      );

      if (errors.length === 0) {
        setErrorAlert(null);
      } else {
        setErrorAlert({
          type: 'form-error',
          errors,
          form: formRef.current,
        });
      }
    }
  };

  useEffect(() => {
    setRenderer(getRenderer(form));
    fileService.setParentProcessKey(processKey);
  }, [form, setRenderer]);

  useEffect(() => {
    const context =
      renderer === Renderers.REACT
        ? {
            confirm: form?.cya?.confirm,
            confirmationEmail: form?.confirmationEmail,
            confirmationMessage: form?.confirmationMessage,
          }
        : {
            confirm: null,
            confirmationMessage: null,
            confirmationEmail: null,
          };
    setFormPanelContext(context);
  }, [renderer, setFormPanelContext]);

  useEffect(() => {
    if (form.type === 'hub-and-spoke') {
      setHideTitle(true);
    } else {
      setHideTitle(false);
    }
  }, [form]);

  useEffect(() => {
    const pathName = window.location.pathname;
    // regex test required to confirm no pageId on the end
    const regex =
      /^\/tasks\/[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    // Only set new page if url has a page ID on the end
    if (
      (pathName.includes(`${processKey}/`) && !pathName.includes('version')) ||
      (pathName.startsWith('/tasks/') && !regex.test(pathName))
    ) {
      setNewPage(pathName.substring(pathName.lastIndexOf('/') + 1));
    }
  }, [window.location.pathname]);

  window.onpopstate = () => {};

  const checkCurrentMandecs = async (mandecsUrl) => {
    const mandecData = await axiosInstance.get(mandecsUrl);
    if (mandecData?.data?.length > 0) {
      navigation.navigate(`/forms/cop-make-your-mandatory-declarations/mandecStarted`);
    } else if (!contexts.data.extendedStaffDetailsContext.linemanagerEmail) {
      navigation.navigate(`/forms/cop-make-your-mandatory-declarations/addYourLineManager`);
    } else {
      navigation.navigate(`/forms/cop-make-your-mandatory-declarations/beforeYouStart`);
    }
  };

  return (
    <Loader
      show={submitting}
      message={<ApplicationSpinner translationKey="submitting" colour={BLACK} />}
      foregroundStyle={{ color: BLACK }}
      backgroundStyle={{ backgroundColor: WHITE }}
    >
      {errorAlert && <FormErrorsAlert errors={errorAlert.errors} form={errorAlert.form} />}
      {renderer === Renderers.FORM_IO && (
        <Form
          form={form}
          ref={formRef}
          onFormLoad={() => {
            const start = new Date();
            MatomoManager.trackWizardPage(formRef, trackPageView);
            setTime({
              ...time,
              start,
            });
          }}
          onNextPage={() => {
            MatomoManager.trackWizardPage(formRef, trackPageView);
            window.scrollTo(0, 0);
          }}
          onPrevPage={() => {
            MatomoManager.trackWizardPage(formRef, trackPageView);
            window.scrollTo(0, 0);
            setErrorAlert(null);
          }}
          submission={augmentedSubmission}
          onSubmit={(submissionData) => {
            setTime({
              ...time,
              end: new Date(),
              submitted: true,
            });
            handleOnSubmit(submissionData, localStorageReference);
          }}
          onChange={(data) => {
            // If we remove this set state the context does not load correctly
            setHasFormChanged(!hasFormChanged);
            if (formRef.current) {
              validate(formRef.current.formio, data);
            }
            // Fixes issue with going between React and FormIO forms
            // where the localStorage can get mixed up
            if (getRenderer(form) === Renderers.FORM_IO) {
              SecureLocalStorageManager.set(localStorageReference, {
                data: data.data,
                metadata: data.metadata,
              });
            }
          }}
          {...(handleOnCustomEvent ? { onCustomEvent: handleOnCustomEvent } : null)}
          onError={(errors) => {
            window.scrollTo(0, 0);
            setErrorAlert({
              type: 'form-error',
              errors,
              form: formRef.current,
            });
          }}
          options={{
            breadcrumbSettings: {
              clickable: false,
            },
            noAlerts: true,
            fileService,
            hooks: {
              beforeCancel: () => {
                setTime({
                  ...time,
                  end: new Date(),
                  submitted: false,
                });
                handleOnCancel();
              },
              buttonSettings: {
                showCancel: true,
              },
              customValidation: (submissionData, next) => {
                /* eslint-disable no-param-reassign, no-shadow */
                const { versionId, id, title, name } = form;
                submissionData.data.form = {
                  formVersionId: versionId,
                  formId: id,
                  title,
                  name,
                  submissionDate: new Date(),
                  submittedBy: keycloak.tokenParsed.email,
                  submissionTeam: keycloak.tokenParsed.team_id,
                  formInstanceId: uuidv4(),
                };
                // processContext, taskContext, keycloakContext and staffDetailsDataContext not needed in request payload
                delete submissionData.data.processContext;
                delete submissionData.data.taskContext;
                delete submissionData.data.keycloakContext;
                delete submissionData.data.staffDetailsDataContext;
                /* eslint-enable no-param-reassign, no-shadow */
                next();
              },
            },
          }}
        />
      )}
      {renderer === Renderers.REACT && (
        <div className="govuk-grid-row govuk-form-group">
          <div className={!className ? 'govuk-grid-column-two-thirds' : className}>
            {form.type === 'hub-and-spoke' && form.title && newPage === 'hub' && (
              <>
                <h1 className="govuk-heading-l">{form.title}</h1>
                <AlertPanel
                  fieldName={changedFieldName}
                  fieldValue={changedFieldValue}
                  lmApprovalStatus={linemanagerApprovalStatus}
                  dlApprovalStatus={delegateApprovalStatus}
                  lmEmail={pendingLinemanagerEmail}
                  dlEmail={pendingDelegateEmail}
                />
              </>
            )}
            <FormRenderer
              {...form}
              data={{
                ...contexts.data,
                ...getExistingFormData(contexts.data.processContext, form.name),
                ...SecureLocalStorageManager.get(localStorageReference).data,
              }}
              newPageId={newPage}
              viewOnly={false}
              hashLink
              hide_title={hideTitle}
              hooks={{
                onChange: (data) => {
                  SecureLocalStorageManager.set(localStorageReference, {
                    data: { ...data },
                  });
                },
                onGoingBack: (state) => {
                  setChangedFieldName(null);
                  setChangedFieldValue(null);
                  if (state && state.docTitle !== undefined) {
                    document.title = state.docTitle;
                  }
                  /* If the state does NOT contain fullPages when going back in a TaskList form, 
                  the next page is a TaskList page, and therefore hubPageActions should be set. */
                  if (form.type === 'task-list') {
                    if (state.fullPages) {
                      setHubPageActions(false);
                    } else {
                      setHubPageActions(true);
                    }
                  }
                },
                onRequest: (req) => formHooks.onRequest(req, keycloak.token),
                onFormLoad: (setCurrentTask) => {
                  let startPage;

                  setFormBusinessKey(
                    { ...getExistingFormData(contexts.data.processContext, form.name) }.businessKey
                  );
                  if (form.type === 'task-list') {
                    setHubPageActions(true);
                  } else {
                    setHubPageActions(false);
                  }

                  if (
                    form.name === 'cop-make-your-mandatory-declarations' &&
                    !contexts.data.processContext
                  ) {
                    const mandecsUrl = `${contexts.data.environmentContext.workflowUrl}/data-service/mandec/running?email=${contexts.data.extendedStaffDetailsContext.email}`;
                    checkCurrentMandecs(mandecsUrl);
                  } else if (form.type === 'task-list' || form.type === 'hub-and-spoke') {
                    startPage = 'hub';
                    if (augmentedSubmission.currentTask) {
                      setCurrentTask(augmentedSubmission.currentTask);
                    }
                  } else if (form.type === 'form-with-task-list') {
                    startPage = getPageForFormWithTask(
                      form,
                      contexts?.data?.processContext?.[`${form.name}`]?.formStatus?.taskPage
                    );
                    if (augmentedSubmission.currentTask) {
                      setCurrentTask(augmentedSubmission.currentTask);
                    }
                  } else {
                    const data = {
                      ...contexts.data,
                      ...SecureLocalStorageManager.get(localStorageReference).data,
                    };
                    startPage =
                      data.processContext !== undefined
                        ? data.processContext[`${form.name}`]?.formStatus?.page || form.pages[0].id
                        : form.pages[0].id;
                  }
                  // If user reloads then newPage is defined
                  const page = newPage === undefined ? startPage : newPage;
                  document.title = getDocumentTitle(coreDocTitle, page, form.pages, {
                    ...contexts.data,
                    ...getExistingFormData(contexts.data.processContext, form.name),
                    ...SecureLocalStorageManager.get(localStorageReference).data,
                    ...SecureLocalStorageManager.get(currentTaskReference),
                  });
                  MatomoManager.trackReactPage(page, trackPageView, 'replace', {
                    docTitle: document.title,
                  });
                },
                onPageChange: (pageId, state) => {
                  document.title = getDocumentTitle(coreDocTitle, pageId, form.pages);

                  if (pageId === 'hub' && form.type === 'task-list') {
                    setHubPageActions(true);
                  } else {
                    setHubPageActions(false);
                  }
                  if (state && Object.keys(state).length > 0) {
                    SecureLocalStorageManager.set(localStorageReference, {
                      data: { ...SecureLocalStorageManager.get(localStorageReference).data },
                    });
                    SecureLocalStorageManager.set(currentTaskReference, state);
                  }
                  document.title = getDocumentTitle(coreDocTitle, pageId, form.pages, {
                    ...contexts.data,
                    ...getExistingFormData(contexts.data.processContext, form.name),
                    ...SecureLocalStorageManager.get(localStorageReference).data,
                    ...SecureLocalStorageManager.get(currentTaskReference),
                  });
                  MatomoManager.trackReactPage(pageId, trackPageView, 'push', {
                    ...state,
                    docTitle: document.title,
                  });
                  if (!pageId) handleOnCancel();
                  setNewPage(undefined);
                },
                onSubmit: async (
                  type,
                  payload,
                  onSuccess,
                  onError,
                  fieldName,
                  fieldValue,
                  pageId
                ) => {
                  if (type === 'submitToEpms') {
                    formHooks.onSubmitToEpms(
                      type,
                      form,
                      payload,
                      keycloak.tokenParsed.email,
                      `${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`,
                      axiosInstance,
                      keycloak
                    );
                  }
                  if (type === 'transitionToEab1') {
                    if (getShouldGoToEabOne(payload)) {
                      prepopulate({ eventMode: payload.modeOfTransport, eventPort: payload.port });
                      setLoading();
                      formHooks.onTransitionToEabOne(
                        form,
                        payload,
                        keycloak.tokenParsed.email,
                        keycloak.tokenParsed.given_name,
                        navigation,
                        axiosInstance
                      );
                    }
                    onSuccess({});
                    return;
                  }
                  try {
                    // validate special items in payload
                    await formHooks.customValidate(form, payload, axiosInstance, pageId, type);

                    const { data, businessKey } = await formHooks.onSubmit(
                      type,
                      form,
                      payload,
                      keycloak.tokenParsed.email,
                      keycloak.tokenParsed.given_name,
                      keycloak.tokenParsed.team_id,
                      axiosInstance,
                      processKey,
                      fileService,
                      contexts.data.environmentContext.attachmentServiceUrl
                    );
                    setFormBusinessKey(businessKey);
                    if (type === 'submit') {
                      // Get the correct message for the submission screen.
                      if (Array.isArray(form?.cya?.confirm)) {
                        const confirmation = form?.cya?.confirm.find((confirm) => {
                          if (Array.isArray(confirm.show_when)) {
                            return FRUtils.Condition.meetsAll(confirm.show_when, payload);
                          }
                          return true;
                        });
                        if (doValueSubstitutionInConfirmationMessage(form)) {
                          confirmation.message = confirmation.message.map((message) =>
                            substituteValuesInConfirmationMessage(message, payload)
                          );
                        }
                        setFormPanelContext({
                          confirm: confirmation,
                          confirmationEmail: form?.confirmationEmail,
                          confirmationMessage: form?.confirmationMessage,
                        });
                        if (doValueSubstitutionInConfirmationTitle(form)) {
                          const { title } = confirmation; // assuming title is a string here
                          const substitutedTitle = substituteValuesInConfirmationTitle(
                            title,
                            payload
                          );
                          confirmation.title = substitutedTitle;
                        }
                        setFormPanelContext({
                          confirm: confirmation,
                          confirmationEmail: form?.confirmationEmail,
                          confirmationMessage: form?.confirmationMessage,
                        });
                      }
                      if (form.type === 'hub-and-spoke') {
                        SecureLocalStorageManager.remove(localStorageReference);
                        const enhancedData = {
                          ...data,
                          linemanagerEmail:
                            typeof linemanagerEmail === 'undefined' ? '' : `${linemanagerEmail}`,
                          delegateEmail:
                            typeof delegateEmail === 'undefined' ? '' : `${delegateEmail}`,
                        };
                        if (typeof handleOnSuccess === 'function') {
                          handleOnSuccess();
                        }
                        onSuccess({ ...enhancedData, businessKey });
                        setChangedFieldName(fieldName);
                        setChangedFieldValue(fieldValue);
                        setHideTitle(true);
                        await keycloak
                          .updateToken(-1)
                          .then(() => {
                            // eslint-disable-next-line no-console
                            console.log('token refreshed');
                          })
                          .catch(() => {
                            client.logout();
                          });
                        await loadGroup(
                          keycloak.tokenParsed.team_id,
                          axiosInstance,
                          source,
                          setCurrentGroup,
                          setGroupLoaded
                        );
                      } else {
                        onSuccess({ ...data, businessKey });
                        if (doRedirect(contexts.data, form)) {
                          navigation.navigate(
                            Utils.interpolateString(form.cya?.path_for_redirect, {
                              businessKey,
                            })
                          );
                        } else {
                          navigation.navigate(
                            `/forms/${processKey}/confirmation/${businessKey}?formTitle=${form.title}`
                          );
                        }
                      }
                    } else if (type === 'saveAndReturn') {
                      onSuccess({ ...data, businessKey });
                      navigation.navigate(
                        `/forms/${processKey}/saved/${businessKey}?formTitle=${form.title}`
                      );
                    } else {
                      onSuccess({ ...data, businessKey });
                      SecureLocalStorageManager.set(localStorageReference, {
                        // The order in which the objects are applied is significant.
                        // The existing local storage data is applied to retain data already present, if any.
                        // The payload is an original object passed to this onSubmit hook holding formStatus.
                        // The data is the output of the call to the onSubmit form hook and holds the id.
                        // The business key is generated on first call to the onSubmit form hook (for new forms).
                        data: {
                          ...SecureLocalStorageManager.get(localStorageReference).data,
                          ...payload,
                          ...data,
                          businessKey,
                        },
                      });
                    }
                  } catch (exception) {
                    const wipeData = Object.prototype.hasOwnProperty.call(exception, 'wipeData')
                      ? exception.wipeData
                      : true;
                    onError(exception.errors, wipeData);
                  }
                },
                onValidate: (page, currentErrors) => validatePage(page, currentErrors),
                onAction: (formData, patch, data, type) =>
                  performAction(formData, patch, data, type),
                onGetComponent,
                onGetCYARows: (page, component, onAction) => {
                  if (isPendingStaffDetailsPage(page?.id)) {
                    updatePendingStaffDetail(page); // otherwise pending will be displayed before it has been approved
                  }

                  if (CompositeTypes.includes(component.type)) {
                    return getCYARowsForComposite(component, page, onAction);
                  }
                  return null;
                },
              }}
            />
            {hubPageActions && formBusinessKey && (
              <ButtonGroup>
                <Button
                  classModifiers="secondary"
                  onClick={() =>
                    navigation.navigate(
                      `/forms/${processKey}/saved/${formBusinessKey}?formTitle=${form.title}`
                    )
                  }
                >
                  Save and Return Later
                </Button>
              </ButtonGroup>
            )}
          </div>
        </div>
      )}
    </Loader>
  );
};

DisplayForm.defaultProps = {
  className: null,
  handleOnCustomEvent: null,
  handleOnSuccess: null,
  interpolateContext: null,
  submitting: false,
  existingSubmission: {},
  setLoading: () => {},
  prepopulate: () => {},
};

DisplayForm.propTypes = {
  form: PropTypes.oneOfType([
    // FormIo forms:
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      versionId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }),
    // React Forms:
    PropTypes.shape({
      version: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      title: PropTypes.string,
      confirmationMessage: PropTypes.string,
      confirmationEmail: PropTypes.bool,
      cya: PropTypes.shape({
        path_for_redirect: PropTypes.string,
        confirm: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
      }),
      components: PropTypes.arrayOf(PropTypes.shape()).isRequired,
      pages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }),
  ]).isRequired,
  className: PropTypes.string,
  handleOnCancel: PropTypes.func.isRequired,
  handleOnCustomEvent: PropTypes.func,
  handleOnSubmit: PropTypes.func.isRequired,
  handleOnSuccess: PropTypes.func,
  interpolateContext: PropTypes.shape({
    taskContext: PropTypes.shape(),
    processContext: PropTypes.shape(),
  }),
  submitting: PropTypes.bool,
  localStorageReference: PropTypes.string.isRequired,
  existingSubmission: PropTypes.shape({ data: PropTypes.shape() }),
  processKey: PropTypes.string.isRequired,
  setLoading: PropTypes.func,
  prepopulate: PropTypes.func,
};

export default DisplayForm;
